<template>
  <!-- HERO-16
    ============================================= -->
    <section id="hero-16" class="hero-section division" style="background-color: white; margin-top: 90px;">
    <div class="container">
        <div class="row d-flex align-items-center">
            <div class="col-md-5 col-lg-6 order-last order-md-2">
                <div class="hero-16-img pc-25 text-center">
                    <img
                        class="img-fluid"
                        src="images/products/people-office-work-day.jpg"
                        alt="hero-image"
                        style="border-radius: 10px;"
                    />
                </div>
            </div>
            <div class="col-md-7 col-lg-6 order-first order-md-2">
                <div class="hero-16-txt">
                    <h2 class="h2-sm" style="color: #065806;">Verify Identity, Ensure Compliance</h2>
                    <p class="p-xl" style="color: #000000;">
                        Streamline KYC validation for your business. Miint Africa's KYC Validation solutions make it easier
                        to verify your customers' identities, ensuring compliance and reducing risk.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>


  <!-- Section 2: Features -->
  <section id="features" class="py-5">
    <div class="container">
      <h2 class="section-title">Advanced KYC Features</h2>
      <div class="row">
        <div class="col-md-6 col-lg-3">
          <div class="icon-box">
            <i class="fas fa-check-circle"></i>
            <h4>Real-time Verification</h4>
            <p>Verify identities instantly with our real-time verification tools.</p>
          </div>
        </div>
        <div class="col-md-6 col-lg-3">
          <div class="icon-box">
            <i class="fas fa-file-upload"></i>
            <h4>Document Scanning</h4>
            <p>Effortlessly scan and validate documents for accuracy and authenticity.</p>
          </div>
        </div>
        <div class="col-md-6 col-lg-3">
          <div class="icon-box">
            <i class="fas fa-plug"></i>
            <h4>API Integration</h4>
            <p>Seamlessly integrate our KYC solutions with your existing systems.</p>
          </div>
        </div>
        <div class="col-md-6 col-lg-3">
          <div class="icon-box">
            <i class="fas fa-cogs"></i>
            <h4>Customizable Workflows</h4>
            <p>Tailor KYC processes to fit your unique business requirements.</p>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Section 3: Benefits -->
  <section id="benefits" class="py-5">
    <div class="container">
      <h2 class="section-title">Benefits of Our KYC Validation</h2>
      <div class="row align-items-center">
        <div class="col-md-6">
          <img
            src="images/products/kyc.png"
            alt="Improved Customer Onboarding"
            class="img-fluid mb-4"
          />
        </div>
        <div class="col-md-6">
          <div class="benefit-card">
            <h4>Enhanced Security</h4>
            <p>Protect your business from fraud with comprehensive verification.</p>
          </div>
          <div class="benefit-card">
            <h4>Regulatory Compliance</h4>
            <p>Ensure adherence to local regulations and compliance standards.</p>
          </div>
          <div class="benefit-card">
            <h4>Reduced Risk</h4>
            <p>Minimize risk with efficient identity verification processes.</p>
          </div>
          <div class="benefit-card">
            <h4>Improved Customer Onboarding</h4>
            <p>Streamline the onboarding process for a better customer experience.</p>
          </div>
        </div>
      </div>
    </div>
  </section>

<!-- Section 4: How It Works -- >
<section id="how-it-works" class="py-5">
    <div class="container">
        <h2 class="section-title">Simple, Efficient KYC</h2>
        <div class="row">
            <div class="col-md-6 col-lg-3 text-center">
                <i class="fas fa-database fa-3x mb-3" style="color: green;"></i>
                <h4>Data Collection</h4>
                <p>Gather necessary data from your customers quickly and securely.</p>
            </div>
            <div class="col-md-6 col-lg-3 text-center">
                <i class="fas fa-user-check fa-3x mb-3" style="color: green;"></i>
                <h4>Verification</h4>
                <p>Use our tools to verify identities in real-time.</p>
            </div>
            <div class="col-md-6 col-lg-3 text-center">
                <i class="fas fa-shield-alt fa-3x mb-3" style="color: green;"></i>
                <h4>Risk Assessment</h4>
                <p>Evaluate risks associated with each customer profile.</p>
            </div>
            <div class="col-md-6 col-lg-3 text-center">
                <i class="fas fa-eye fa-3x mb-3" style="color: green;"></i>
                <h4>Ongoing Monitoring</h4>
                <p>Continuously monitor customer activities to detect anomalies.</p>
            </div>
        </div>
    </div>
</section> --->


  <!-- Section 5: Supported Documents -->
  <section id="supported-documents" class="py-5" style="background-color: #ffffff;">
    <div class="container">
      <h2 class="section-title">We Support Various Documents</h2>
      
      <div class="row">
        <div class="col-md-6 col-lg-3">
          <img
            src="images/products/phone_x.png"
            alt="ID"
            class="img-fluid"
          />
          <p>Phone Number</p>
        </div>
        <div class="col-md-6 col-lg-3">
          <img
            src="images/products/passport_picture.png"
            alt="Passport"
            class="img-fluid"
          />
          <p>Passport</p>
        </div>
      </div>
    </div>
  </section>

 
 
</template>

<script>
export default {
  name: 'appKYCValidation',
}
</script>
<style>

 
body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  margin: 0;
  padding: 0;
}

.container {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
}

.hero-section {
  
  color: #fff;
  text-align: center;
  padding: 100px 20px;
}

.hero-section h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.cta-btn {
  display: inline-block;
  padding: 15px 25px;
  background: #007bff;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  transition: background 0.3s;
}

.cta-btn:hover {
  background: #0056b3;
}

.section-title {
  text-align: center;
  margin: 40px 0 20px;
  font-size: 2rem;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}

.col-md-6, .col-md-3, .col-lg-3, .col-lg-4 {
  padding: 15px;
  flex: 1;
}

.feature-card, .how-it-works-card, .benefit-card, .pricing-card, .testimonial-card {
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.feature-card i, .how-it-works-card i, .benefit-card i {
  font-size: 3rem;
  margin-bottom: 10px;
  color: #007bff;
}

.pricing-card {
  border: 2px solid #007bff;
}

.pricing-card h4 {
  font-size: 1.5rem;
}

.pricing-card ul {
  list-style: none;
  padding: 0;
}

.bg-light {
  background: #f9f9f9;
}

.testimonial-card {
  background: #e9ecef;
  border-radius: 10px;
  padding: 20px;
  margin: 20px 0;
}

.outline {
  border: 2px solid #007bff;
  background: transparent;
  color: #007bff;
}

.outline:hover {
  background: #007bff;
  color: #fff;
}

</style>