<template>
  <div>
    <!-- Hero Section -->
    <section class="hero" style="background-color: white;">
      <div>
        <h1 class="hero-title">Join Our Team</h1>
        <p>Transforming Africa's digital landscape together</p>
        <button class="btn-green">Explore Opportunities</button>
      </div>
    </section>

    <!-- Our Culture Section -->
    <section class="section">
      <div class="text-center">
        <h2 class="section-title">Our Culture</h2>
        <p>
          At Miint Africa, we believe in fostering an innovative, supportive, and dynamic work environment
          where each team member can thrive and make a significant impact.
        </p>
      </div>
      <div class="cards">
        <div class="card">
          <div>
            <h5>Innovation</h5>
            <p>We constantly push the boundaries of technology, creating solutions that empower businesses to excel.</p>
          </div>
        </div>
        <div class="card">
          <div>
            <h5>Community</h5>
            <p>Our team is like a family. We value collaboration, support, and the pursuit of shared goals.</p>
          </div>
        </div>
      </div>
    </section>

    <!-- Benefits Section -->
    <section class="section bg-light">
      <div class="text-center">
        <h2 class="section-title">Perks & Benefits</h2>
      </div>
      <div class="cards">
        <div class="card">
          <i class="fas fa-dollar-sign icon"></i>
          <div>
            <h5>Competitive Salaries</h5>
            <p>We offer attractive compensation packages to reward your hard work and dedication.</p>
          </div>
        </div>
        <div class="card">
          <i class="fas fa-briefcase-medical icon"></i>
          <div>
            <h5>Health Insurance</h5>
            <p>Comprehensive health coverage to keep you and your family safe and healthy.</p>
          </div>
        </div>
        <div class="card">
          <i class="fas fa-suitcase-rolling icon"></i>
          <div>
            <h5>Paid Time Off</h5>
            <p>We value work-life balance and offer generous time off to recharge.</p>
          </div>
        </div>
        <div class="card">
          <i class="fas fa-chalkboard-teacher icon"></i>
          <div>
            <h5>Professional Development</h5>
            <p>Grow your skills with learning opportunities and career advancement support.</p>
          </div>
        </div>
      </div>
    </section>

    <!-- Job Openings Section -->
    <section class="section">
      <div class="text-center">
        <h2 class="section-title">Current Openings</h2>
      </div>
      <div class="jobs-list">
        <div v-for="job in jobs" :key="job.id" class="job">
          <div>
            <h5>{{ job.title }}</h5>
            <p>{{ job.department }} - {{ job.location }}</p>
          </div>
          <button @click="apply(job)">Apply Now</button>
        </div>
      </div>
    </section>

    <!-- Call to Action Section -->
    <section class="cta btn-green">
      <h2 class="cta-title">Ready to Join?</h2>
      <p>Be part of our mission to drive digital transformation across Africa.</p>
      <button style="color: #00bb70;">Explore Opportunities</button>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      jobs: [
        { id: 1, title: 'Software Developer', department: 'IT Department', location: 'Kampala, Uganda' },
        { id: 2, title: 'Sales Executive', department: 'Sales Department', location: 'Nairobi, Kenya' }
        // Add more job openings as needed
      ]
    }
  },
  methods: {
    apply(job) {
      // Logic to handle job application
      alert(`Applying for ${job.title}`);
    }
  }
}
</script>

<style>
    body {
      font-family: Arial, sans-serif;
      margin: 0;
      padding: 0;
      background-color: #f4f4f4;
    }
    .hero {
      background: url('/src/assets/images/bg-08.jpg') no-repeat center center/cover;
      background-color: #f4f4f4;
      height: 100vh;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
    .hero h1 {
      font-size: 3rem;
      margin-bottom: 1rem;
    }
    .hero button {
      padding: 1rem 2rem;
      background-color: #ff4a17;
      border: none;
      color: white;
      font-size: 1.2rem;
      cursor: pointer;
    }
    .section {
      padding: 60px 20px;
    }
    .cards {
      display: flex;
      justify-content: space-between;
      margin-bottom: 2rem;
    }
    .card {
      background-color: white;
      padding: 20px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      flex: 1;
      margin: 0 10px;
      display: flex;
      align-items: center;
    }
    .card img {
      width: 50px;
      margin-right: 20px;
    }
    .card h5 {
      margin-bottom: 0.5rem;
      font-size: 1.2rem;
    }
    .icon {
      font-size: 3rem;
      color: #ff4a17;
      margin-bottom: 10px;
    }
    .text-center {
      text-align: center;
    }
    .section-title {
      font-size: 2rem;
      margin-bottom: 1rem;
    }
    .jobs-list {
      margin-top: 2rem;
    }
    .job {
      background: white;
      padding: 20px;
      margin-bottom: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
    .cta {
      background-color: #00bb70;
      color: white;
      padding: 2rem;
      text-align: center;
    }
    .cta button {
      padding: 1rem 2rem;
      background-color: white;
      color: #ff4a17;
      border: none;
      cursor: pointer;
    }
  </style>
