<template>
  <div>
    <div id="hero-16" class="hero-section division" style="background-color: white; margin-top: 90px;">
      <div class="container">
        <div class="row d-flex align-items-center">
          <div class="col-md-5 col-lg-6 order-last order-md-2">
            <div class="hero-16-img pc-25 text-center">
              <img class="img-fluid" src="images/products/gettyimages-6795.jpg" alt="hero-image"
                style="border-radius:10px;">
            </div>
          </div>
          <div class="col-md-7 col-lg-6 order-first order-md-2">
            <div class="hero-16-txt">
              <h2 class="h2-sm" style="color:#065806;">Collections Made Easy</h2>
              <p class="p-xl" style="color: black;">Streamline Your Payment Processes. Miint Africa's Collections
                solutions help you manage payments efficiently, reduce bad debt, and enhance customer satisfaction through
                automated and secure processes.</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Section 2: Features -->
    <div id="features" class="content-section py-5">
      <div class="container">
        <h2 class="text-center mb-5">Key Features</h2>
        <div class="row">
          <!-- Feature 1: Automated Reminders -->
          <div class="col-md-6 col-lg-3">
            <div class="feature-card text-center">
              <i class="fas fa-bell fa-3x icon"></i>
              <h5>Automated Reminders</h5>
              <p>Send timely reminders to ensure payments are made on schedule without manual intervention.</p>
            </div>
          </div>
          <!-- Feature 2: Customizable Payment Plans -->
          <div class="col-md-6 col-lg-3">
            <div class="feature-card text-center">
              <i class="fas fa-tasks fa-3x icon"></i>
              <h5>Customizable Payment Plans</h5>
              <p>Create tailored payment plans that fit your customers' needs and boost collection rates.</p>
            </div>
          </div>
          <!-- Feature 3: Real-time Reporting -->
          <div class="col-md-6 col-lg-3">
            <div class="feature-card text-center">
              <i class="fas fa-chart-line fa-3x icon"></i>
              <h5>Real-time Reporting</h5>
              <p>Monitor your collections performance instantly with detailed and actionable reports.</p>
            </div>
          </div>
          <!-- Feature 4: Secure Payment Processing -->
          <div class="col-md-6 col-lg-3">
            <div class="feature-card text-center">
              <i class="fas fa-shield-alt fa-3x icon"></i>
              <h5>Secure Payment Processing</h5>
              <p>Ensure all transactions are secure with our advanced encryption and security protocols.</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Section 3: Benefits -->
    <div id="benefits" class="content-section py-5" style="background-color: #ffffff;">
      <div class="container">
        <h2 class="text-center mb-5">Benefits of Our Collections Solutions</h2>
        <div class="row align-items-center">
          <!-- Benefits Text -->
          <div class="col-md-6 py-5">
            <div class="benefits-list">
              <!-- Benefit 1: Improved Cash Flow -->
              <div class="benefit-card text-left mb-4">
                <i class="fas fa-dollar-sign fa-2x icon mb-2"></i>
                <h5>Improved Cash Flow</h5>
                <p>Accelerate your revenue cycle with efficient and timely collections, ensuring a steady cash flow for
                  your business.</p>
              </div>
              <!-- Benefit 2: Reduced Bad Debt -->
              <div class="benefit-card text-left mb-4">
                <i class="fas fa-thumbs-down fa-2x icon mb-2"></i>
                <h5>Reduced Bad Debt</h5>
                <p>Minimize losses by effectively managing overdue accounts and reducing the incidence of bad debt.</p>
              </div>
              <!-- Benefit 3: Enhanced Customer Experience -->
              <div class="benefit-card text-left mb-4">
                <i class="fas fa-smile fa-2x icon mb-2"></i>
                <h5>Enhanced Customer Experience</h5>
                <p>Provide a seamless and respectful collection process that maintains positive relationships with your
                  customers.</p>
              </div>
            </div>
          </div>
          <!-- Benefits Image -->
          <div class="col-md-6 text-center">
            <img src="images/products/colletions_p.jpg" alt="Benefits Image" class="img-fluid rounded">
          </div>
        </div>
      </div>
    </div>

    <!-- Section 4: How It Works -->
    <div id="how-it-works" class="content-section py-5" style="background-color: #048504;">
      <div class="container">
        <h2 class="text-center mb-5">Simple, Efficient Collections</h2>
        <div class="row py-5">
          <!-- Step 1: Setup -->
          <div class="col-md-6 col-lg-3">
            <div class="feature-card text-center">
              <i class="fas fa-cogs fa-3x icon"></i>
              <h5>Setup</h5>
              <p>Configure your collection parameters and integrate with your existing systems effortlessly.</p>
            </div>
          </div>
          <!-- Step 2: Automated Reminders -->
          <div class="col-md-6 col-lg-3">
            <div class="feature-card text-center">
              <i class="fas fa-bell-slash fa-3x icon"></i>
              <h5>Automated Reminders</h5>
              <p>Schedule automatic reminders to prompt timely payments from your customers.</p>
            </div>
          </div>
          <!-- Step 3: Secure Payment Processing -->
          <div class="col-md-6 col-lg-3">
            <div class="feature-card text-center">
              <i class="fas fa-lock fa-3x icon"></i>
              <h5>Secure Payment Processing</h5>
              <p>Handle all transactions securely with our encrypted payment gateway.</p>
            </div>
          </div>
          <!-- Step 4: Real-time Reporting -->
          <div class="col-md-6 col-lg-3">
            <div class="feature-card text-center">
              <i class="fas fa-chart-pie fa-3x icon"></i>
              <h5>Real-time Reporting</h5>
              <p>Access up-to-the-minute reports to track your collections performance.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Section 5: Integration -- >
<div id="integration" class="content-section py-5">
  <div class="container">
    <h2 class="text-center mb-5">Seamless Integration</h2>
    <h4 class="text-center mb-4">Integrate with Existing Systems</h4>
    <p class="text-center mb-5">
      Our Collections solutions easily integrate with your current accounting software, CRM, and other business systems, ensuring a smooth and unified workflow.
    </p>
    <div class="row justify-content-center integration-icons">
      <div class="col-3 col-md-2 text-center">
        <i class="fas fa-users-cog fa-3x" style="color: #065806;"></i>
        <p>CRM</p>
      </div>
      <div class="col-3 col-md-2 text-center">
        <i class="fas fa-calculator fa-3x" style="color: #065806;"></i>
        <p>Accounting</p>
      </div>
      <div class="col-3 col-md-2 text-center">
        <i class="fas fa-network-wired fa-3x" style="color: #065806;"></i>
        <p>ERP</p>
      </div>
      <div class="col-3 col-md-2 text-center">
        <i class="fas fa-code fa-3x" style="color: #065806;"></i>
        <p>API</p>
      </div>
    </div>
    <p class="text-center mt-4">
      Experience the benefits of integrated systems, including reduced manual data entry, enhanced accuracy, and improved operational efficiency.
    </p>
  </div>
</section> --->

  </div>
</template>

<script>
export default {
  name: "appCollections",
};
</script>

<style>
/* Custom Styles */

/* Hero Section */
.hero-section {
  position: relative;
  color: white;
  background-size: cover;
  background-position: center;
  padding: 150px 0;
  text-align: center;
}

.hero-section h1 {
  font-size: 3rem;
  font-weight: bold;
}

.hero-section p.lead {
  font-size: 1.25rem;
  margin-bottom: 30px;
}

.hero-section .btn {
  padding: 10px 30px;
  font-size: 1.1rem;
}

/* Features, Benefits, Use Cases Sections */
.feature-card,
.benefit-card,
.use-case-card {
  padding: 30px 20px;
  transition: transform 0.3s, box-shadow 0.3s;
  background-color: white;
  border-radius: 8px;
  margin-bottom: 30px;
}

.feature-card:hover,
.benefit-card:hover,
.use-case-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

.feature-card .icon,
.benefit-card .icon,
.use-case-card .icon {
  color: #28a745;
  /* Green color */
  margin-bottom: 15px;
}

/* Pricing Section */
.pricing-card {
  border: 1px solid #dee2e6;
  border-radius: 8px;
  transition: transform 0.3s, box-shadow 0.3s;
}

.pricing-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

.pricing-card .card-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.pricing-card .card-subtitle {
  font-size: 1.25rem;
  color: #6c757d;
}

.pricing-card ul {
  list-style: none;
  padding: 0;
  margin-bottom: 20px;
}

.pricing-card ul li {
  margin-bottom: 10px;
}

.pricing-card ul li i {
  color: #28a745;
  margin-right: 8px;
}

/* Testimonials Section */
.testimonial-card {
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s, box-shadow 0.3s;
}

.testimonial-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

.testimonial-card img {
  border: 3px solid #28a745;
}

.testimonial-card h5 {
  margin-top: 15px;
  font-weight: bold;
}

.testimonial-card p.text-muted {
  font-style: italic;
}

/* Call-to-Action Sections */
.cta-section {
  padding: 80px 0;
  text-align: center;
}

.cta-section h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.cta-section p {
  font-size: 1.2rem;
  margin-bottom: 30px;
}

/* Integration Logos */
.integration-logos img {
  max-width: 100px;
  margin: 15px;
  filter: grayscale(100%);
  transition: filter 0.3s;
}

.integration-logos img:hover {
  filter: grayscale(0%);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .hero-section {
    padding: 80px 0;
  }

  .hero-section h1 {
    font-size: 2rem;
  }

  .hero-section p.lead {
    font-size: 1rem;
  }

  .feature-card,
  .benefit-card,
  .use-case-card {
    padding: 20px 15px;
  }

  .pricing-card {
    margin-bottom: 30px;
  }
}
</style>
