<template>
  <div>
    <!-- HERO-16
    ============================================= -->
    <section id="hero-16" class="hero-section division" style="background-color: white; margin-top: 90px;">
      <div class="container">
        <div class="row d-flex align-items-center">
          <div class="col-md-5 col-lg-6 order-last order-md-2">
            <div class="hero-16-img pc-25 text-center">
              <img class="img-fluid" src="images/products/gettyim12.jpg" alt="hero-image" style="border-radius:10px;">
            </div>
          </div>
          <div class="col-md-7 col-lg-6 order-first order-md-2">
            <div class="hero-16-txt">
              <h2 class="h2-sm" style="color:#065806;">Deliver scalable messaging with a single, programmable SMS API</h2>
              <p class="p-xl" style="color: black;">Get streamlined access to telecom infrastructure, consume our SMS, USSD,
                Airtime, and Collections APIs, and turn your ideas into reality.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Section 1: Key Features -->
    <div id="brands-2" class="wide-70 brands-section division" style="background-color: #ffffff;">
      <div class="container">
        <!-- BRANDS TITLE -->
        <div class="row justify-content-center">
          <div class="col-lg-8">
            <div class="brands-title text-center">
              <p class="p-xl">Our Key Features</p>
            </div>
          </div>
        </div>
        <!-- BRANDS-2 WRAPPER -->
        <div class="brands-2-wrapper">
          <div class="row">
            <!-- Feature 1: Reliable Delivery -->
            <div class="col-md-6 col-lg-3 text-center mb-4">
              <i class="fas fa-check-circle fa-3x text-success mb-3"></i>
              <h5>Reliable Delivery</h5>
              <p>Ensure your messages reach your audience without fail, every time.</p>
            </div>
            <!-- Feature 2: Personalized Messages -->
            <div class="col-md-6 col-lg-3 text-center mb-4">
              <i class="fas fa-user-edit fa-3x text-success mb-3"></i>
              <h5>Personalized Messages</h5>
              <p>Customize your messages to engage customers on a personal level.</p>
            </div>
            <!-- Feature 3: Real-time Reporting -->
            <div class="col-md-6 col-lg-3 text-center mb-4">
              <i class="fas fa-chart-line fa-3x text-success mb-3"></i>
              <h5>Real-time Reporting</h5>
              <p>Monitor your campaigns with instant analytics and insights.</p>
            </div>
            <!-- Feature 4: API Integration -->
            <div class="col-md-6 col-lg-3 text-center mb-4">
              <i class="fas fa-plug fa-3x text-success mb-3"></i>
              <h5>API Integration</h5>
              <p>Seamlessly integrate SMS services with your existing systems.</p>
            </div>
          </div>
        </div>
      </div>
    </div>

   <!-- Section 2: SMS Solutions -->
<section id="content-1" class="content-1 content-section division" style="background-color: #ffffff;">
  <div class="container">
    <div class="row d-flex align-items-center">
      <div class="col-md-5 col-lg-6 order-last order-md-2">
            <div class="hero-16-img pc-25 text-center">
              <img class="img-fluid" src="images/products/portrait-happy.jpg" alt="hero-image" style="border-radius:10px;">
            </div>
          </div>
      <div class="col-md-5 col-lg-6 order-first order-md-2">
        <div class="row">
          <div class="col-4">
            <div class="fbox-8 mb-40 wow fadeInUp">
              <div class="fbox-img bg-whitesmoke-gradient">
                <i class="fas fa-bell fa-3x" style="color: green;"></i> <!-- Notifications Icon -->
              </div>
              <h5 class="h5-md">Notifications</h5>
              <p class="p-md">Keep your customers informed with timely updates and alerts.</p>
            </div>
          </div>
          <div class="col-4">
            <div class="fbox-8 mb-40 wow fadeInUp">
              <div class="fbox-img bg-whitesmoke-gradient">
                <i class="fas fa-tags fa-3x" style="color: green;"></i> <!-- Promotions Icon -->
              </div>
              <h5 class="h5-md">Promotions</h5>
              <p class="p-md">Boost sales with targeted promotional campaigns directly to users.</p>
            </div>
          </div>
          <div class="col-4">
            <div class="fbox-8 mb-40 wow fadeInUp">
              <div class="fbox-img bg-whitesmoke-gradient">
                <i class="fas fa-calendar-check fa-3x" style="color: green;"></i> <!-- Reminders Icon -->
              </div>
              <h5 class="h5-md">Reminders</h5>
              <p class="p-md">Send reminders for appointments, payments, and more.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


 
   
  </div>
</template>

<script>
export default {
  name: "appSMS",
  data() {
    return {
      // You can define data properties here if needed
    };
  },
  methods: {
    // Define any methods you may need here
  },
};
</script>

<style>
/* Custom Styles */
#content-1 {
  background-color: #ffffff; /* White background */
  padding: 40px 0; /* Optional: Adds some padding for spacing */
} /* Closing brace added here */

#brands-2 {
  background-color: #ffffff; /* White background */
  padding: 60px 0; /* Optional: Adds some padding */
}

/* Hero Section */
.hero-section {
  position: relative;
  color: white;
}

.hero-section h1 {
  font-size: 3rem;
  font-weight: bold;
}

.hero-section p.lead {
  font-size: 1.25rem;
}

.hero-section .btn {
  padding: 10px 30px;
  font-size: 1.1rem;
}

/* Features Section */
.feature-card,
.benefit-card,
.use-case-card {
  padding: 20px;
  transition: transform 0.3s, box-shadow 0.3s;
}

.feature-card:hover,
.benefit-card:hover,
.use-case-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.feature-card .icon,
.benefit-card .icon,
.use-case-card .icon {
  color: #28a745; /* Green color */
  margin-bottom: 15px;
}

/* Pricing Section */
.card-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.card-subtitle {
  font-size: 1.25rem;
  color: #6c757d;
}

.pricing-card ul {
  list-style: none;
  padding: 0;
}

.pricing-card ul li {
  margin-bottom: 10px;
}

.pricing-card ul li i {
  color: #28a745;
  margin-right: 8px;
}

/* Testimonials Section */
.testimonial-card img {
  border: 2px solid #28a745;
}

.testimonial-card h5 {
  margin-top: 15px;
  font-weight: bold;
}

.testimonial-card p.text-muted {
  font-style: italic;
}

/* Call-to-Action Section */
#call-to-action h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

#call-to-action p {
  font-size: 1.2rem;
  margin-bottom: 30px;
}

#call-to-action .btn {
  padding: 10px 25px;
  font-size: 1.1rem;
}

/* Icons Styling */
.icon {
  width: 60px;
  height: 60px;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .hero-section {
    padding: 60px 0;
  }
  
  .hero-section h1 {
    font-size: 2.5rem;
  }
  
  .hero-section p.lead {
    font-size: 1rem;
  }
  
  .benefits-list .benefit-item,
  .use-cases .use-case-card,
  .features .feature-card {
    margin-bottom: 20px;
  }
}
</style>