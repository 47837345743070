<template>
  <div>
    <!-- HERO-16 -->
    <section id="hero-16" class="hero-section division" style="background-color: white; margin-top: 90px;">
      <div class="container">
        <div class="row d-flex align-items-center">
          <div class="col-md-7 col-lg-6 order-first order-md-2">
            <div class="hero-16-txt">
              <h2 class="h2-sm" style="color:#065806;">Airtime Made Easy</h2>
              <p class="p-xl">Top-up airtime for your customers, employees, or partners.
                  Miint Africa’s Airtime solutions make it easy to provide seamless top-ups for your customers or team. Whether it’s 
              for rewards, incentives, or daily use, our platform supports real-time airtime recharge across multiple operators
                </p>
            </div>
          </div>
          <div class="col-md-5 col-lg-6 order-last order-md-2">
            <div class="hero-16-img pc-25 text-center">
              <img class="img-fluid" src="images/products/Buy Airtime.jpg" alt="hero-image" style="border-radius:10px;">
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Section 2: Features -->
    <section id="features" class="py-5" style="background-color: #ffffff;">
      <div class="container">
        <h2 class="section-title">Key Features</h2>
        <div class="row">
          <div class="col-md-6 col-lg-3">
            <div class="icon-box" style="background-color: #f5f5f5; padding: 20px; border-radius: 10px;">
              <i class="fas fa-sync-alt"></i>
              <h4>Real-time Recharge</h4>
              <p>Recharge airtime instantly without delays, ensuring immediate service delivery.</p>
            </div>
          </div>
          <div class="col-md-6 col-lg-3">
            <div class="icon-box" style="background-color: #f5f5f5; padding: 20px; border-radius: 10px;">
              <i class="fas fa-network-wired"></i>
              <h4>Multiple Operator</h4>
              <p>Support for all major telecom operators, offering wide coverage and flexibility.</p>
            </div>
          </div>
          <div class="col-md-6 col-lg-3">
            <div class="icon-box" style="background-color: #f5f5f5; padding: 20px; border-radius: 10px;">
              <i class="fas fa-code"></i>
              <h4>API Integration</h4>
              <p>Easily integrate our airtime services into your platform via API.</p>
            </div>
          </div>
          <div class="col-md-6 col-lg-3">
            <div class="icon-box" style="background-color: #f5f5f5; padding: 20px; border-radius: 10px;">
              <i class="fas fa-cogs"></i>
              <h4>Customizable Solutions</h4>
              <p>Tailor our airtime services to fit your business needs, whether for bulk purchases or individual top-ups.</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Benefits -->
    <section id="benefits">
      <div class="container">
        <h2 class="section-title text-center">Benefits of Our Airtime Solutions</h2>
        <div class="row align-items-center mt-4">
          <!-- Column 1: Image -->
          <div class="col-md-6">
            <img src="images/products/front-view.png" alt="Benefits of Airtime Solutions" class="img-fluid" style="border-radius: 10px;">
          </div>
    
          <!-- Column 2: Benefit Cards -->
          <div class="col-md-6">
            <div class="row">
              <!-- Benefit 1: Convenience -->
              <div class="col-md-12 mb-4">
                <div class="card">
                  <div class="card-body">
                    <h4>Convenience</h4>
                    <p>Top-up airtime anytime, anywhere, ensuring uninterrupted communication for your customers and team members.</p>
                  </div>
                </div>
              </div>
    
              <!-- Benefit 2: Increased Customer Loyalty -->
              <div class="col-md-12 mb-4">
                <div class="card">
                  <div class="card-body">
                    <h4>Increased Customer Loyalty</h4>
                    <p>Build loyalty and keep customers engaged with your services by offering seamless airtime top-ups as a reward or incentive.</p>
                  </div>
                </div>
              </div>
    
              <!-- Benefit 3: Reduced Churn -->
              <div class="col-md-12 mb-4">
                <div class="card">
                  <div class="card-body">
                    <h4>Reduced Churn</h4>
                    <p>Airtime incentives help reduce customer turnover by keeping them connected and satisfied with your services.</p>
                  </div>
                </div>
              </div>
    
              <!-- Benefit 4: Increased Revenue -->
              <div class="col-md-12 mb-4">
                <div class="card">
                  <div class="card-body">
                    <h4>Increased Revenue</h4>
                    <p>Offer airtime as a service to your clients, boosting your revenue and adding value to your business offerings.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Section 4: How It Works -->
    <section id="how-it-works" class="py-5" style="background-color: #048504;">
      <div class="container">
        <h2 class="section-title" style="color: #ffffff;">Simple, Efficient Airtime</h2>
        <div class="row">
          <div class="col-md-3">
            <div class="icon-box">
              <i class="fas fa-user-plus"></i>
              <h4>Signup</h4>
              <p>Create an account to start offering airtime services to your customers or team.</p>
            </div>
          </div>
          <div class="col-md-3">
            <div class="icon-box">
              <i class="fas fa-wallet"></i>
              <h4>Fund Your Account</h4>
              <p>Add funds to your Miint Africa account to begin making airtime transactions.</p>
            </div>
          </div>
          <div class="col-md-3">
            <div class="icon-box">
              <i class="fas fa-mobile-alt"></i>
              <h4>Top-up Airtime</h4>
              <p>Top-up airtime for customers, employees, or partners in real-time.</p>
            </div>
          </div>
          <div class="col-md-3">
            <div class="icon-box">
              <i class="fas fa-chart-bar"></i>
              <h4>Track Transactions</h4>
              <p>Monitor all airtime top-ups through detailed reporting and transaction history.</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Supported Operators -- >
    <section id="supported-operators" class="py-5" style="background-color: #ffffff;">
      <div class="container">
        <h2 class="section-title">We Support Major Operators</h2>
        <p class="mb-4">Miint Africa’s airtime solutions are compatible with all major telecom operators across the region, ensuring widespread coverage and accessibility.</p>
        <div class="row justify-content-center">
          <div class="col-3 col-sm-2">
            <img src="images/products/MTN.png" alt="MTN" class="img-fluid">
          </div>
          <div class="col-3 col-sm-2">
            <img src="images/products/airtel.png" alt="Airtel" class="img-fluid">
          </div>
          <div class="col-3 col-sm-2">
            <img src="images/products/lycamobile.png" alt="Lycamobile" class="img-fluid">
          </div>
          <div class="col-3 col-sm-2">
            <img src="images/products/utel.png" alt="UTel" class="img-fluid">
          </div>
        </div>
      </div>
    </section>  --->

    <!-- Bootstrap 5 JS and dependencies -->
    <!--<script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/js/bootstrap.bundle.min.js"></script> -->
  </div>
</template>

<script>
export default {
  name: 'appAirtime',
}
</script>

<style>
/* General Styles */
body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


.cta-button {
    padding: 10px 30px;
    font-size: 1.2rem;
    border-radius: 5px;
    background-color: #28a745;
    color: white;
    text-decoration: none;
    transition: background-color 0.3s ease;
}
.cta-button:hover {
    background-color: #218838;
    color: white;
}

/* Section Titles */
.section-title {
    font-size: 2rem;
    margin-bottom: 40px;
    text-align: center;
    font-weight: 700;
}

/* Icon Boxes */
.icon-box {
    text-align: center;
    padding: 20px;
    transition: transform 0.3s, box-shadow 0.3s;
    border-radius: 8px;
    background-color: white;
    margin-bottom: 30px;
}
.icon-box:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 20px rgba(0,0,0,0.1);
}
.icon-box i {
    font-size: 2.5rem;
    color: #28a745;
    margin-bottom: 15px;
}

/* Supported Operators */
.operator-logo img {
    max-width: 100px;
    margin: 10px;
    filter: grayscale(100%);
    transition: filter 0.3s ease;
}
.operator-logo img:hover {
    filter: grayscale(0%);
}

/* Testimonials */
.testimonial {
    text-align: center;
    padding: 30px;
    border-radius: 8px;
    background-color: white;
    box-shadow: 0 4px 10px rgba(0,0,0,0.05);
    transition: transform 0.3s, box-shadow 0.3s;
    margin-bottom: 30px;
}
.testimonial:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0,0,0,0.1);
}
.testimonial img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-bottom: 15px;
    border: 3px solid #28a745;
}
.testimonial p {
    font-style: italic;
    margin-bottom: 10px;
}
.testimonial h5 {
    font-weight: 700;
    margin-bottom: 0;
}
.testimonial p.designation {
    color: #6c757d;
    font-size: 0.9rem;
}

/* Pricing */
.pricing-section {
    background-color: #f9f9f9;
    padding: 60px 20px;
}
.pricing-card {
    border: 1px solid #dee2e6;
    border-radius: 8px;
    transition: transform 0.3s, box-shadow 0.3s;
    margin-bottom: 30px;
}
.pricing-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 20px rgba(0,0,0,0.1);
}
.pricing-card .card-header {
    background-color: #28a745;
    color: white;
    border-bottom: none;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    font-size: 1.5rem;
    font-weight: 700;
}
.pricing-card .card-body {
    text-align: center;
}
.pricing-card .price {
    font-size: 2rem;
    font-weight: 700;
    margin: 20px 0;
}
.pricing-card ul {
    list-style: none;
    padding: 0;
    margin-bottom: 20px;
}
.pricing-card ul li {
    margin-bottom: 10px;
    font-size: 1rem;
}
.pricing-card ul li i {
    color: #28a745;
    margin-right: 8px;
}
.pricing-card .btn {
    padding: 10px 25px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}
.pricing-card .btn-primary {
    background-color: #28a745;
    border: none;
}
.pricing-card .btn-primary:hover {
    background-color: #218838;
}

/* Call-to-Action */
.cta-section {
    background-color: #28a745;
    color: white;
    text-align: center;
    padding: 80px 20px;
}
.cta-section h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
}
.cta-section p {
    font-size: 1.2rem;
    margin-bottom: 30px;
}
.cta-section .btn {
    padding: 10px 25px;
    font-size: 1.1rem;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    margin: 5px;
}
.cta-section .btn-primary {
    background-color: #fff;
    color: #28a745;
    border: none;
}
.cta-section .btn-primary:hover {
    background-color: #e6e6e6;
    color: #28a745;
}
.cta-section .btn-outline-light {
    color: #fff;
    border-color: #fff;
}
.cta-section .btn-outline-light:hover {
    background-color: #fff;
    color: #28a745;
    border-color: #fff;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .icon-box {
        margin-bottom: 20px;
    }
    .operator-logo img {
        max-width: 80px;
    }
    .section-title {
        font-size: 1.8rem;
    }
    .hero-section h1 {
        font-size: 2.5rem;
    }
    .hero-section p.lead {
        font-size: 1rem;
    }
}
</style>
