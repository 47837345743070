<template>
  <!-- HERO-16 Section -->
  <div id="hero-16" class="hero-section division"
    style="background-color: #ffffff; position: relative; overflow: hidden; border: none; box-shadow: none; margin-top: -60px;">
    <!-- Use negative margin-top to pull the entire section up -->
    <div class="hero-background"
      style="background: url('images/home_bg.jpg') no-repeat center; background-size: cover; height: 1400px; width: 100%; position: absolute; top: 0; left: 0; z-index: 1;">
    </div> <!-- Adjusted height -->
    <div class="overlay"
      style="background: rgba(255, 254, 254, 0.137); position: absolute; top: 0; left: 0; height: 100%; width: 100%; z-index: 2;">
    </div>

    <div class="container" style="position: relative; z-index: 3;">
      <div class="row justify-content-end"> <!-- Use justify-content-end to align the content to the right -->
        <div class="col-md-6" style="text-align: right; padding-right: 30px;">
          <!-- Add padding-right to push the content further to the right -->
          <h2 class="h2-sm" style="color: #f9fff9; margin-bottom: 20px;">Empowering Aggregation Solutions Across Africa
            for Africa</h2>
          <p class="p-xl" style="color: #fffbfb; margin-bottom: 20px;">Get Streamlined access to telecom infrastructure,
            consume our SMS, USSD,
            Airtime, and Collections APIs and turn your ideas into reality.
          </p>
        </div>
      </div>
    </div>

  </div>

  <div id="content-1" class="content-1 content-section division" style="background-color: #ffffff;">
    <div class="container">
      <div class="row d-flex align-items-left">
        <div class="col-md-7 col-lg-6 order-last order-md-2">
          <div class="txt-block left-column wow fadeInRight">
            <img src="/images/hero-48.jpg" />
          </div>
        </div>
        <div class="col-md-5 col-lg-6 order-first order-md-2 mt-10">
          <div class="row">
            <h3 class="h2-xs" style="text-align: left;">Simple, easy integrations</h3>
            <p class="p-lg justify-content-start" style="text-align: left">
              Miint Africa API allows you to seamlessly integrate telecom services into your business, helping you reach
              new heights. With our SMS, payment acceptance, and USSD APIs, you can transform your operations into a true
              powerhouse.
            </p>
            <ul style="text-align: left;">
              <li>1. Send SMS</li>
              <li>2. Accept payments through the Collections API</li>
              <li>3. Send Airtime</li>
              <li>4. KYC Validation for Phone numbers or National IDs</li>
              <li>5. Create USSD applications</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div id="content-1" class="content-1 content-section division" style="background-color: #ffffff;">
    <div class="container">
      <div class="row d-flex align-items-left">
        <div class="col-md-7 col-lg-6 order-last order-md-2">
          <div class="txt-block left-column wow fadeInRight">
            <h3 class="h2-xs" style="text-align: left;">Build custom aggregation experiences</h3>
            <p class="justify-content-start" style="text-align:left;">Developers appreciate well-documented, comprehensive
              APIs that enable them to create everything from simple hobby projects to sophisticated financial products
              serving hundreds of thousands of customers. If you can imagine it, you can build it with Miint Africa.</p>
            <ul style="text-align: left;">
              <li>1. JWT Authentication</li>
              <li>2. JSON Based API</li>
              <li>3. Check Status API</li>
            </ul>
          </div>
        </div>
        <div class="col-md-5 col-lg-6 order-first order-md-2 mt-10">
          <div class="row" style="border-radius: 10px;">
            <img src="/images/hero_46.jpg" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="brands-1" class="pb-100 brands-section py-5">
    <div class="container">
      <!-- BRANDS TITLE -->
      <div class="row justify-content-center">
        <div class="col-md-10 col-lg-9">
          <div class="brands-title mb-50">
            <h5 class="s-17">Brands using the Miint Platform</h5>
          </div>
        </div>
      </div>

      <!-- BRANDS CAROUSEL -->
      <div class="row" style="background-color: white;">
        <div class="col text-center">
          <div class="owl-carousel brands-carousel-6">
            <div class="brand-logo">
              <a href="#"><img class="img-fluid" src="/images/clients/bank-of-africa.png" alt="brand-logo"></a>
            </div>
            <div class="brand-logo">
              <a href="#"><img class="img-fluid" src="/images/clients/cafe-javas.png" alt="brand-logo"></a>
            </div>
            <div class="brand-logo">
              <a href="#"><img class="img-fluid" src="/images/clients/dfcu.jpeg" alt="brand-logo"></a>
            </div>
            <div class="brand-logo">
              <a href="#"><img class="img-fluid" src="/images/clients/gtbank.jpg" alt="brand-logo"></a>
            </div>
            <div class="brand-logo">
              <a href="#"><img class="img-fluid" src="/images/clients/Absa_Logo_1.png" alt="brand-logo"></a>
            </div>
            <div class="brand-logo">
              <a href="#"><img class="img-fluid" src="/images/clients/kcb.jpg" alt="brand-logo"></a>
            </div>
            <div class="brand-logo">
              <a href="#"><img class="img-fluid" src="/images/clients/OpportunityBank.png" alt="brand-logo"></a>
            </div>
            <div class="brand-logo">
              <a href="#"><img class="img-fluid" src="/images/clients/roke-telkom.png" alt="brand-logo"></a>
            </div>
            <div class="brand-logo">
              <a href="#"><img class="img-fluid" src="/images/clients/Standard_Chartered.png" alt="brand-logo"></a>
            </div>
          </div>
        </div>
      </div> <!-- END BRANDS CAROUSEL -->
    </div> <!-- End container -->
  </div> <!-- END BRANDS-1 -->
  <div id="content-4" class="content-4 pt-40 content-section" style="background-color: #048504">
    <div class="bg-inner bg-04 pb-50 division">
      <div class="container">
        <div class="row d-flex align-items-center">
          <div class="col-md-5 col-lg-6 order-end order-md-2">
            <div class="content-4-img left-column wow fadeInRight mt-2">
              <img class="img-fluid" src="images/hero-49.jpg" alt="content-image">
            </div>
          </div>
          <div class="col-md-7 col-lg-6 order-first order-md-2">
            <div class="txt-block right-column white-color wow fadeInLeft">
              <h2 class="h2-xs" style="text-align:left;">Ready to get started?</h2>
              <p class="p-lg" style="text-align:left;">Create an account and instantly start building amazing apps and
                selling your goods and
                products online.</p>
              <a href="https://dashboard.miintafrica.com/register" class="btn btn-white tra-grey-hover"
                style="margin-top: 10px;">Get Started</a>
            </div>
          </div> <!-- END CONTENT TXT -->


        </div> <!-- End row -->
      </div> <!-- End container -->
    </div> <!-- End Inner Background -->
  </div> <!-- END CONTENT-4 -->
</template>

<script>
/* eslint-disable */
export default {
  name: 'appHome',
  data() {
    return {
      clients: [
        { name: 'Absa', logo: '/images/clients/Absa_Logo_1.png' },
        { name: 'Standard Chartered', logo: '/images/clients/Standard_Chartered.png' },
        { name: 'KCB', logo: '/images/clients/kcb.jpg' },
        { name: 'dfcu', logo: '/images/clients/dfcu.jpeg' },
        { name: 'Cafe Javas', logo: '/images/clients/cafe-javas.png' }
      ],

      products: [
        {
          title: 'SMS',
          description: "Miint Africa's SMS solution empowers businesses to communicate efficiently with customers.",
          icon: 'fas fa-comment-dots'
        },
        {
          title: 'Collections',
          description: "Miint Africa's Collections solution streamlines payment processes for businesses.",
          icon: 'fas fa-credit-card'
        },
        {
          title: 'Airtime',
          description: "Miint Africa's Airtime solution enables seamless recharge for customers or partners.",
          icon: 'fas fa-mobile-alt'
        },
        {
          title: 'KYC Validation',
          description: "Miint Africa's KYC Validation ensures compliance and security for businesses.",
          icon: 'fas fa-check-circle'
        },
        {
          title: 'USSD ShortCode Rental',
          description: "Miint Africa's USSD ShortCode Rental enables businesses to engage customers interactively.",
          icon: 'fas fa-mobile-alt'
        }
      ]
    }
  },
  mounted() {
    $('.brands-carousel-6').owlCarousel({
      loop: true,
      margin: 10,
      nav: true,
      autoplay: true,
      autoplayTimeout: 3000,
      responsive: {
        0: {
          items: 2
        },
        600: {
          items: 4
        },
        1000: {
          items: 6
        }
      }
    });

    $('.ussd-carousel').owlCarousel({
      loop: true,
      margin: 10,
      nav: false,
      autoplay: true,
      autoplayTimeout: 3000,
      responsive: {
        0: {
          items: 1
        },
        600: {
          items: 2
        },
        1000: {
          items: 3
        }
      }
    });
  }
}
</script>

<style scoped>
.hero-background {
  background: url('@/assets/images/home_bg.jpg')no-repeat center center;
}

.overlay {
  background: rgba(255, 254, 254, 0.137);
}

.fbox-img {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
  width: 70px;
  border-radius: 50%;
}
</style>
