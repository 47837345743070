<template>
  <footer id="footer-4" class="footer division">
    <div class="container">
      <div class="row">
        <!-- Footer Company Info -->
        <div class="col-lg-3">
          <div class="footer-info mb-40">
            <img class="footer-logo" :src="logo" alt="footer-logo">

            <p style="font-size:14px; text-align: left;" class="mt-3">
              Founded in 2001, True African is akin to a mobile and online Financial Services Hub, the largest
              Financial Services Hub of its kind in the country. True African provides custom-tailored plug-and-play
              software solutions to address specific business needs.
            </p>
          </div>
        </div>

        <!-- About Section -->
        <div class="col-sm-6 col-md-3 col-lg-2 offset-xl-1">
          <div class="footer-links mb-40">
            <h6 class="h6-xl">About</h6>
            <ul class="foo-links text-secondary clearfix">
              <li>
                <p class="p-md"><router-link to="#">About Us</router-link></p>
              </li>
              <li>
                <p class="p-md"><router-link to="#">Our Team</router-link></p>
              </li>
              <li>
                <p class="p-md"><router-link to="#">Careers</router-link></p>
              </li>
            </ul>
          </div>
        </div>

        <!-- Contact Section -->
        <div class="col-sm-6 col-md-4 col-lg-3">
          <div class="footer-links mb-40">
            <h6 class="h6-xl">UGANDA OFFICE</h6>
            <p style="font-size:15px;">
              Telephone: +256 393 230 380 <br>
              Mobile: +256 772 744289 <br>
              E-mail: <a href="mailto:info@trueafrican.com">info@trueafrican.com</a> <br>
              Address: True African (U) Ltd.<br>
              Plot 3-5, Level 5,<br>
              Block A Nakawa Business Park,<br>
              P.O Box 71843 Kampala, Uganda
            </p>
          </div>
        </div>
      </div>

      <!-- Bottom Footer -->
      <hr>
      <div class="bottom-footer">
        <div class="row row-cols-1 row-cols-md-2 d-flex align-items-center">
          <div class="col">
            <div class="footer-copyright">
              <p>&copy; {{ currentYear }} Miint Africa. All Rights Reserved</p>
            </div>
          </div>
          <div class="col">
            <ul class="bottom-footer-list text-secondary text-end">
              <li><router-link to="#">Privacy Policy</router-link></li>
              <li><router-link to="#">Terms & Conditions</router-link></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: 'AppFooter',

  data() {
    return {
      currentYear: new Date().getFullYear(), // Automatically fetch the current year
      logo: '/images/TA_logo.png'
    };
  },
};
</script>
