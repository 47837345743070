<template>
  <header :class="['header', 'tra-menu', 'navbar-dark', { 'scrolled': isScrolled }]">
    <div class="header-wrapper">
      <div class="wsmobileheader clearfix">
        <span class="smllogo"><img :src="logo" alt="mobile-logo" /></span>
        <a id="wsnavtoggle" class="wsanimated-arrow"><span></span></a>
      </div>
      <div class="wsmainfull menu clearfix">
        <div class="wsmainwp clearfix">
          <div class="desktoplogo">
            <router-link to="/" class="logo-black"><img :src="logo" alt="header-logo"></router-link>
          </div>
          <div class="desktoplogo">
            <router-link to="/" class="logo-white"><img :src="logo" alt="header-logo"></router-link>
          </div>
          <nav class="wsmenu clearfix">
            <ul class="wsmenu-list nav-green-hover">
              <li aria-haspopup="true" class="mg_link">
                <a href="#" :class="{ 'white-text': !isScrolled }">Products<span class="wsarrow"></span></a>
                <ul class="sub-menu">
                  <li class="fst-li"><router-link to="/sms">SMS</router-link></li>
                  <li><router-link to="/collections">Collections</router-link></li>
                  <li><router-link to="/airtime">Airtime</router-link></li>
                  <li><router-link to="/kyc-validation">KYC Validation</router-link></li>
                  <li><router-link to="/ussd">USSD ShortCode Rental</router-link></li>
                </ul>
              </li>
              <li aria-haspopup="true"><router-link to="/why-miint" :class="{ 'white-text': !isScrolled }">Why Miint</router-link></li>
              <li aria-haspopup="true"><router-link to="/prices" :class="{ 'white-text': !isScrolled }">Prices</router-link></li>
              <li aria-haspopup="true"><a href="https://miint-africa.gitbook.io/docs" v-bind:class="{ 'white-text': !isScrolled }">Developers</a></li>
              <li class="nl-simple" aria-haspopup="true">
                <a
                  href="https://dashboard.miintafrica.com"
                  :class="['btn', 'btn-tra-green', { 'green-text': isScrolled }]"
                  style="color: white;"> <!-- Default color as white -->
                  Sign In
                </a>
              </li>
              <li class="nl-simple" aria-haspopup="true">
                <a href="https://dashboard.miintafrica.com/register" class="btn btn-green tra-grey-hover last-link">Get Started</a>
              </li>
            </ul>
          </nav> <!-- END MAIN MENU -->
        </div>
      </div> <!-- END NAVIGATION MENU -->
    </div> <!-- End header-wrapper -->
  </header> <!-- END HEADER -->
</template>

<script>
export default {
  name: 'appHeader',
  data() {
    return {
      logo: '/images/mint_logo_3.png', // Update this path to point to the public folder
      isScrolled: false
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.isScrolled = window.scrollY > 0;
    }
  }
};
</script>

<style scoped>
.header-wrapper {
  transition: background-color 0.3s;
}

.header.scrolled .header-wrapper {
  background-color: white;
}

.wsmobileheader .smllogo img {
  max-width: 120px;
}

.desktoplogo img {
  max-width: 150px;
}

.wsmenu-list {
  display: flex;
  list-style: none;
  padding-left: 0;
}

.wsmenu-list li {
  margin-right: 20px;
}

/* Default text color for links */
.wsmenu-list li a {
  color: #ffffff; /* White color before scrolling */
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s; /* Smooth color transition */
}

/* Change text color on hover */
.wsmenu-list li a:hover {
  color: #048504; /* Green on hover */
}

/* Change text color when scrolled */
.header.scrolled .wsmenu-list li a {
  color: #048504; /* Green color when scrolled */
}

/* Specific items to be white in font color */
.white-text {
  color: #ffffff !important; /* White color before scrolling */
}

/* Specific items to be green in font color */
.green-text {
  color: #048504 !important; /* Green color after scrolling */
}

/* Additional styles for better visibility */
.header.scrolled .wsmenu-list li a:hover {
  color: #0a7d0a; /* Darker green on hover when scrolled */
}

/* Button text color */
.btn-tra-green {
  color: #ffffff !important; /* White text color for the button */
}

/* Ensure Sign In button is styled correctly */
.btn-tra-green.green-text {
  color: #048504 !important; /* Green text when scrolled */
}

/* Add other styles or import them from an external stylesheet */
</style>
