<template>
  <div>
    <!-- HERO SECTION -->
    <section id="about-hero" class="hero-section division" style="background-color: white; margin-top: 90px;">
      <div class="container">
        <div class="row d-flex align-items-center">
          <div class="col-md-6">
            <div class="hero-txt">
              <h2 class="h2-sm">About Miint Africa</h2>
              <p class="p-xl">
                Empowering Businesses Across Africa with Innovative
                Communication and Payment Solutions.
              </p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="hero-img text-center">
              <img
                class="img-fluid"
                src="/images/products/1_y-U2s0iMkW1lAlvyahygJw.jpg" 
                alt="About Us Hero Image"
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- WHO WE ARE SECTION -->
    <section id="who-we-are" class="content-section division">
      <div class="container">
        <div class="row d-flex align-items-center">
          <div class="col-md-6">
            <div class="img-block">
              <img
                class="img-fluid"
                src="/images/products/1684168884784.jpg" 
                alt="Miint Africa Team"
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="txt-block right-column wow fadeInLeft">
              <h3 class="h2-xs">Who We Are</h3>
              <p>
                Miint Africa is a premier provider of cutting-edge communication
                and payment solutions tailored for businesses across the African
                continent. Established with the mission to simplify digital
                operations, we empower enterprises to achieve seamless
                connectivity and secure transactions.
              </p>
              <p>
                Our dedicated team combines industry expertise with innovative
                technology to deliver solutions that drive growth and efficiency
                for our clients.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- OUR MISSION & VISION SECTION -->
    <section
      id="mission-vision"
      class="content-section division"
      style="background-color: #ffffff"
    >
      <div class="container">
        <div class="row d-flex align-items-center">
          <div class="col-md-6">
            <div class="row">
              <!-- Card 1: Mission -->
              <div class="col-md-12 mb-4">
                <div class="card d-flex flex-row align-items-center">
                  <div class="card-body">
                    <h5 class="card-title h2-xs">Our Mission</h5>
                    <p class="card-text">
                      <strong>Mission:</strong> To deliver innovative and
                      reliable communication and payment solutions that empower
                      businesses to thrive in a digital economy.
                    </p>
                  </div>
                </div>
              </div>

              <!-- Card 2: Vision -->
              <div class="col-md-12 mb-4">
                <div class="card d-flex flex-row align-items-center">
                  <div class="card-body">
                    <h5 class="card-title h2-xs">Our Vision</h5>
                    <p class="card-text">
                      <strong>Vision:</strong> To be Africa’s most trusted
                      technology partner, driving digital transformation and
                      fostering sustainable growth across diverse markets.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="img-block text-center">
              <img
                class="img-fluid"
                src="/images/products/bbpa-international-womens-day-2024.webp"  
                alt="Mission and Vision"
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- OUR CORE VALUES SECTION -->
    <section id="core-values" class="content-section division py-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 text-center py-5">
            <h3 class="h2-xs">Our Core Values</h3>
            <p class="p-lg">
              At Miint Africa, our core values are the foundation of our success
              and guide our interactions with clients, partners, and each other.
            </p>
          </div>
        </div>
        <div class="row">
          <!-- Value 1: Integrity -->
          <div class="col-md-6 col-lg-3">
            <div class="feature-box text-center">
              <i class="fas fa-handshake icon-large"></i>
              <h5 class="h5-md">Integrity</h5>
              <p>
                We uphold the highest ethical standards, ensuring honesty and
                transparency in all our dealings.
              </p>
            </div>
          </div>
          <!-- Value 2: Innovation -->
          <div class="col-md-6 col-lg-3">
            <div class="feature-box text-center">
              <i class="fas fa-lightbulb icon-large"></i>
              <h5 class="h5-md">Innovation</h5>
              <p>
                We continuously seek out new technologies and ideas to deliver
                state-of-the-art solutions.
              </p>
            </div>
          </div>
          <!-- Value 3: Customer Focus -->
          <div class="col-md-6 col-lg-3">
            <div class="feature-box text-center">
              <i class="fas fa-users icon-large"></i>
              <h5 class="h5-md">Customer Focus</h5>
              <p>
                Our clients are at the heart of everything we do. We strive to
                exceed their expectations every day.
              </p>
            </div>
          </div>
          <!-- Value 4: Collaboration -->
          <div class="col-md-6 col-lg-3">
            <div class="feature-box text-center">
              <i class="fas fa-network-wired icon-large"></i>
              <h5 class="h5-md">Collaboration</h5>
              <p>
                We believe in the power of teamwork and partnerships to achieve
                common goals.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- CALL TO ACTION SECTION -->
    <section id="cta" class="content-section division">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 text-center">
            <h3 class="h2-xs">Ready to Transform Your Business?</h3>
            <p class="p-lg">
              Join thousands of businesses leveraging Miint Africa's innovative
              solutions to drive growth and efficiency.
            </p>
            <a href="contact.html" class="btn btn-green tra-grey-hover"
              >Get Started Today</a
            >
          </div>
        </div>
      </div>
    </section>
  </div>
</template>


<script>
export default {
  name: "AboutUs",
};
</script>

 <!-- Custom Styles for About Us Page -->
 <style>
 /* Icon Styling */
 .icon-large {
     font-size: 48px; /* Adjust size as needed */
     color: green; /* Green color */
     margin-bottom: 15px;
 }

 /* Feature Box Styling */
 .feature-box {
     padding: 20px;
     border: 1px solid #e0e0e0;
     border-radius: 8px;
     transition: transform 0.3s, box-shadow 0.3s;
     background-color: #ffffff;
     text-align: center;
 }

 .feature-box:hover {
     transform: translateY(-10px);
     box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
 }

 .feature-box h5 {
     margin-top: 15px;
     margin-bottom: 10px;
     color: #065806;
 }

 .feature-box p {
     font-size: 16px;
     color: #555;
 }

 /* Responsive Image Adjustments */
 .hero-section img {
     border-radius: 10px;
     max-height: 400px;
     object-fit: cover;
     width: 100%;
 }

 .content-section img {
     border-radius: 10px;
     width: 100%;
     height: auto;
 }

 /* Adjusting Hero Section Padding to Prevent Overlap */
 body {
     padding-top: 80px; /* Adjust this value based on your header's height */
 }

 /* Additional Styling for Better Visuals */
 .txt-block h3,
 .txt-block h2 {
     color: #065806;
 }

 .hero-txt h2,
 .hero-txt p {
     color: #065806;
 }

 /* Optional: Smooth Scrolling for Anchor Links */
 html {
     scroll-behavior: smooth;
 }
</style>