<template>
  <div>
    <!-- HERO-16 -->
    <section id="hero-16" class="hero-section division">
      <div class="container">
        <div class="row d-flex align-items-center">
          <div class="col-md-7 col-lg-6 order-first order-md-2">
            <div class="hero-16-txt">
              <h2 class="h2-sm" style="color:#065806;">Meet Our Team</h2>
              <p class="p-xl">Dedicated professionals driving innovation</p>
            </div>
          </div>
          <div class="col-md-5 col-lg-6 order-last order-md-2">
            <div class="hero-16-img pc-25 text-center">
              <img
                class="img-fluid"
                src="images/products/seed-blog4-1200x747.jpg"
                alt="hero-image"
                style="border-radius:10px;"
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Section 2: Leadership Team -->
    <section id="leadership" class="py-5">
      <div class="container">
        <h2 class="section-title">Leadership</h2>
        <div class="row">
          <div class="col-md-4 bio-card">
            <img src="https://via.placeholder.com/150" alt="John Doe" />
            <h4>John Doe</h4>
            <p>CEO</p>
            <p>
              John is a visionary leader with over 15 years of experience in the tech industry, driving innovation and growth at Miint Africa.
            </p>
            <a href="https://www.linkedin.com/in/johndoe" class="btn btn-link">LinkedIn</a>
          </div>
          <div class="col-md-4 bio-card">
            <img src="https://via.placeholder.com/150" alt="Jane Smith" />
            <h4>Jane Smith</h4>
            <p>CTO</p>
            <p>
              With a background in software engineering, Jane leads our tech team, ensuring we stay at the forefront of technology.
            </p>
            <a href="https://www.linkedin.com/in/janesmith" class="btn btn-link">LinkedIn</a>
          </div>
          <div class="col-md-4 bio-card">
            <img src="https://via.placeholder.com/150" alt="Alice Johnson" />
            <h4>Alice Johnson</h4>
            <p>COO</p>
            <p>
              Alice oversees operations and ensures that our services meet the highest standards of quality and efficiency.
            </p>
            <a href="https://www.linkedin.com/in/alicejohnson" class="btn btn-link">LinkedIn</a>
          </div>
        </div>
      </div>
    </section>

    <!-- Section 3: Management Team -->
    <section id="management" class="py-5 bg-light">
      <div class="container">
        <h2 class="section-title">Management</h2>
        <div class="row">
          <div class="col-md-4 bio-card">
            <img src="https://via.placeholder.com/150" alt="Michael Brown" />
            <h4>Michael Brown</h4>
            <p>Head of Marketing</p>
            <p>
              Michael has a proven track record in digital marketing and leads our efforts to connect with clients.
            </p>
            <a href="https://www.linkedin.com/in/michaelbrown" class="btn btn-link">LinkedIn</a>
          </div>
          <div class="col-md-4 bio-card">
            <img src="https://via.placeholder.com/150" alt="Sarah Davis" />
            <h4>Sarah Davis</h4>
            <p>Head of Sales</p>
            <p>
              With extensive experience in sales, Sarah is committed to driving growth and building relationships.
            </p>
            <a href="https://www.linkedin.com/in/sarahdavis" class="btn btn-link">LinkedIn</a>
          </div>
          <div class="col-md-4 bio-card">
            <img src="https://via.placeholder.com/150" alt="David Wilson" />
            <h4>David Wilson</h4>
            <p>Head of Customer Support</p>
            <p>
              David ensures our customers receive top-notch support and satisfaction with our services.
            </p>
            <a href="https://www.linkedin.com/in/davidwilson" class="btn btn-link">LinkedIn</a>
          </div>
        </div>
      </div>
    </section>

    <!-- Section 4: Team Members -->
    <section id="team-members" class="py-5">
      <div class="container">
        <h2 class="section-title">Team Members</h2>
        <div class="row">
          <div class="col-md-4 bio-card">
            <img src="https://via.placeholder.com/150" alt="Emily Clark" />
            <h4>Emily Clark</h4>
            <p>Product Manager</p>
            <p>
              Emily drives product development and is passionate about creating innovative solutions.
            </p>
            <a href="https://www.linkedin.com/in/emilyclark" class="btn btn-link">LinkedIn</a>
          </div>
          <div class="col-md-4 bio-card">
            <img src="https://via.placeholder.com/150" alt="James Martinez" />
            <h4>James Martinez</h4>
            <p>Software Engineer</p>
            <p>
              James is part of our development team, working on the latest features and enhancements.
            </p>
            <a href="https://www.linkedin.com/in/jamesmartinez" class="btn btn-link">LinkedIn</a>
          </div>
          <div class="col-md-4 bio-card">
            <img src="https://via.placeholder.com/150" alt="Linda Taylor" />
            <h4>Linda Taylor</h4>
            <p>UX/UI Designer</p>
            <p>
              Linda focuses on creating user-friendly interfaces and enhancing user experience.
            </p>
            <a href="https://www.linkedin.com/in/lindataylor" class="btn btn-link">LinkedIn</a>
          </div>
        </div>
      </div>
    </section>

    <!-- Section 5: Advisors -->
    <section id="advisors" class="py-5 bg-light">
      <div class="container">
        <h2 class="section-title">Advisors</h2>
        <div class="row">
          <div class="col-md-4 bio-card">
            <img src="https://via.placeholder.com/150" alt="Mark Thompson" />
            <h4>Mark Thompson</h4>
            <p>Senior Advisor</p>
            <p>
              Mark brings decades of experience in the finance sector to guide our strategic initiatives.
            </p>
            <a href="https://www.linkedin.com/in/markthompson" class="btn btn-link">LinkedIn</a>
          </div>
          <div class="col-md-4 bio-card">
            <img src="https://via.placeholder.com/150" alt="Anna Johnson" />
            <h4>Anna Johnson</h4>
            <p>Legal Advisor</p>
            <p>
              Anna provides legal counsel and ensures compliance with regulations across all operations.
            </p>
            <a href="https://www.linkedin.com/in/annajohnson" class="btn btn-link">LinkedIn</a>
          </div>
          <div class="col-md-4 bio-card">
            <img src="https://via.placeholder.com/150" alt="Brian Roberts" />
            <h4>Brian Roberts</h4>
            <p>Technology Advisor</p>
            <p>
              Brian advises on technology trends and helps us stay ahead in innovation.
            </p>
            <a href="https://www.linkedin.com/in/brianroberts" class="btn btn-link">LinkedIn</a>
          </div>
        </div>
      </div>
    </section>

    <!-- Section 6: Join Our Team -->
    <section class="join-team py-5">
      <div class="container">
        <h2>Join Our Team</h2>
        <p>
          If you are passionate about making a difference and want to be part of an innovative company,<br />
          <a href="/careers" class="btn btn-link">check our careers page</a> for opportunities!
        </p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'appTeam',
};
</script>

<style scoped>
/* Add any specific styles for this component here */
.bio-card {
  text-align: center;
  margin-bottom: 30px;
}

.bio-card img {
  border-radius: 50%;
  margin-bottom: 15px;
}

.section-title {
  margin-bottom: 20px;
  color: #065806;
}

.join-team {
  text-align: center;
}
</style>
