<template>
  <div id="app">
    <component :is="currentHeader" />
    <router-view />
    <AppFooter />
  </div>
</template>

<script>
import AppHeader from '@/components/common/appHeader.vue'; // General header
import AppHeader0 from '@/components/common/appHeader0.vue'; // Header for appHome
import AppFooter from '@/components/common/appFooter.vue'; 

export default {
  name: 'App',
  components: {
    AppFooter,
  },
  computed: {
    currentHeader() {
      // Return the appropriate header based on the current route name
      return this.$route.name === 'home' ? AppHeader0 : AppHeader;
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
