// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import appHome from '@/views/appHome.vue';  
import appAbout from '@/components/appAbout.vue';
import appAirtime from '@/components/appAirtime.vue';  // corrected casing
import appCareers from '@/components/appCareers.vue';
import appCollections from '@/components/appCollections.vue';  // corrected casing
import appKYCValidation from '@/components/appKYCValidation.vue';  // corrected casing
import appPrices from '@/components/appPrices.vue';  // corrected casing
import appPrivacyPolicy from '@/components/PrivacyPolicy.vue';
import appTermsConditions from '@/components/TermsConditions.vue';
import appSMS from '@/components/appSMS.vue';  // corrected casing
import appTeam from '@/components/appTeam.vue';
import appUSSD from '@/components/appUSSD.vue';  // corrected casing
import appWhyMiint from '@/components/appWhyMiint.vue';  // corrected casing

const routes = [
  { path: '/', component: appHome, name: 'home' },
  { path: '/about', component: appAbout, name: 'about' }, // Updated path for About page
  { path: '/airtime', component: appAirtime, name: 'airtime' },  // Updated path
  { path: '/careers', component: appCareers, name: 'careers' }, // Updated path
  { path: '/collections', component: appCollections, name: 'collections' },  // Updated path
  { path: '/kyc-validation', component: appKYCValidation, name: 'kyc-validation' },  // Updated path
  { path: '/prices', component: appPrices, name: 'prices' },  // Updated path
  { path: '/privacy-policy', component: appPrivacyPolicy, name: 'privacy-policy' }, // Updated path
  { path: '/terms-conditions', component: appTermsConditions, name: 'terms-conditions' }, // Updated path
  { path: '/sms', component: appSMS, name: 'sms' },  // Updated path
  { path: '/team', component: appTeam, name: 'team' }, // Updated path
  { path: '/ussd', component: appUSSD, name: 'ussd' },  // Updated path
  { path: '/why-miint', component: appWhyMiint, name: 'why-miint' },  // Updated path
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
