<template>
  <div class="terms-conditions" style="background-color: white; margin-top: 90px;">
    <!-- Hero Section -->
    <section class="hero-section">
      <div class="container">
        <h1 class="text-center">Terms and Conditions</h1>
        <p class="text-center">
          Welcome to Miint Africa! By accessing or using our services, you agree to comply with and be bound by these terms and conditions.
        </p>
      </div>
    </section>

    <!-- Acceptance of Terms -->
    <section class="acceptance-of-terms py-5">
      <div class="container">
        <h2><i class="fas fa-check-circle"></i> Acceptance of Terms</h2>
        <p>By using our website and services, you agree to these Terms and Conditions in full. If you do not agree with any part of these terms, you must not use our services.</p>
      </div>
    </section>

    <!-- Use of Services -->
    <section class="use-of-services py-5 bg-light">
      <div class="container">
        <h2><i class="fas fa-users"></i> Use of Services</h2>
        <p>You agree to use our services only for lawful purposes and in a manner that does not infringe the rights of, restrict, or inhibit anyone else’s use of the services.</p>
      </div>
    </section>

    <!-- User Accounts -->
    <section class="user-accounts py-5">
      <div class="container">
        <h2><i class="fas fa-user-shield"></i> User Accounts</h2>
        <p>To access certain features of our services, you may be required to create an account. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.</p>
      </div>
    </section>

    <!-- Intellectual Property -->
    <section class="intellectual-property py-5 bg-light">
      <div class="container">
        <h2><i class="fas fa-gavel"></i> Intellectual Property</h2>
        <p>All content, trademarks, and other intellectual property on our website are the property of Miint Africa or our licensors. You may not use, reproduce, or distribute any content without our prior written consent.</p>
      </div>
    </section>

    <!-- Limitation of Liability -->
    <section class="limitation-of-liability py-5">
      <div class="container">
        <h2><i class="fas fa-exclamation-circle"></i> Limitation of Liability</h2>
        <p>To the fullest extent permitted by law, Miint Africa shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising from your use of or inability to use our services.</p>
      </div>
    </section>

    <!-- Governing Law -->
    <section class="governing-law py-5 bg-light">
      <div class="container">
        <h2><i class="fas fa-balance-scale"></i> Governing Law</h2>
        <p>These Terms and Conditions shall be governed by and construed in accordance with the laws of Uganda, without regard to its conflict of law principles.</p>
      </div>
    </section>

    <!-- Changes to Terms -->
    <section class="changes-to-terms py-5">
      <div class="container">
        <h2><i class="fas fa-sync"></i> Changes to Terms</h2>
        <p>We may update these Terms and Conditions from time to time. Any changes will be posted on this page, and your continued use of our services after such changes constitutes your acceptance of the new Terms and Conditions.</p>
      </div>
    </section>

    <!-- Contact Us -->
    <section class="contact-us py-5 bg-light">
      <div class="container">
        <h2><i class="fas fa-envelope"></i> Contact Us</h2>
        <p>If you have any questions about these Terms and Conditions, please contact us:</p>
        <p>Email: <a href="mailto:support@miintafrica.com">support@miintafrica.com</a></p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'TermsConditions',
}
</script>
<style>
/* General Styles */
body {
  font-family: 'Arial', sans-serif;
  line-height: 1.6;
  margin: 0;
  padding: 0;
}

.container {
  max-width: 1200px;
  margin: auto;
  padding: 0 15px;
}

/* Hero Section */
.hero-section {
  background-color: #f4f4f4;
  padding: 60px 0;
}

.hero-section h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.hero-section p {
  font-size: 1.2rem;
}

/* Section Styles */
section {
  margin-bottom: 40px;
}

section h2 {
  font-size: 2rem;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

section h2 i {
  margin-right: 10px; /* Space between icon and text */
  color: #007bff; /* Change this color to match your brand */
}

section p {
  font-size: 1rem;
  margin-bottom: 10px;
}

/* Background Light */
.bg-light {
  background-color: #f9f9f9;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .hero-section h1 {
    font-size: 2rem;
  }

  section h2 {
    font-size: 1.5rem;
  }
}

</style>