<template>
  <div class="privacy-policy">
    <!-- Hero Section -->
    <section class="hero-section" style="background-color: white; margin-top: 90px;">
      <div class="container">
        <h1 class="text-center">Privacy Policy</h1>
        <p class="text-center">
          Your privacy is important to us. This policy outlines how we collect, use, and protect your personal information.
        </p>
      </div>
    </section>

    <!-- Information Collection -->
    <section class="info-collection py-5">
      <div class="container">
        <h2>Information We Collect</h2>
        <p>We may collect the following types of information:</p>
        <ul>
          <li>Personal identification information (name, email, phone number, etc.)</li>
          <li>Technical data (IP address, browser type, operating system, etc.)</li>
          <li>Usage data (information about how you use our website and services)</li>
        </ul>
      </div>
    </section>

    <!-- Use of Information -->
    <section class="use-of-info py-5 bg-light">
      <div class="container">
        <h2>How We Use Your Information</h2>
        <p>We use the information we collect for various purposes, including:</p>
        <ul>
          <li>To provide and maintain our services</li>
          <li>To notify you about changes to our services</li>
          <li>To allow you to participate in interactive features of our services</li>
          <li>To provide customer support</li>
          <li>To gather analysis or valuable information so that we can improve our services</li>
          <li>To monitor the usage of our services</li>
          <li>To detect, prevent and address technical issues</li>
        </ul>
      </div>
    </section>

    <!-- Data Protection -->
    <section class="data-protection py-5">
      <div class="container">
        <h2>Data Protection</h2>
        <p>We are committed to ensuring the security of your personal information. We take appropriate security measures to protect against unauthorized access, alteration, disclosure, or destruction of your personal information.</p>
      </div>
    </section>

    <!-- Sharing of Information -->
    <section class="sharing-info py-5 bg-light">
      <div class="container">
        <h2>Sharing Your Information</h2>
        <p>We may share your personal information with:</p>
        <ul>
          <li>Service providers who assist us in operating our website and services</li>
          <li>Third parties for marketing purposes, with your consent</li>
          <li>In response to legal requests or to protect our rights</li>
        </ul>
      </div>
    </section>

    <!-- User Rights -->
    <section class="user-rights py-5">
      <div class="container">
        <h2>Your Rights</h2>
        <p>You have the right to:</p>
        <ul>
          <li>Access the personal information we hold about you</li>
          <li>Request correction of inaccurate personal information</li>
          <li>Request deletion of your personal information</li>
          <li>Object to processing of your personal information</li>
        </ul>
      </div>
    </section>

    <!-- Changes to this Privacy Policy -->
    <section class="changes-policy py-5 bg-light">
      <div class="container">
        <h2>Changes to This Privacy Policy</h2>
        <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
        <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
      </div>
    </section>

    <!-- Contact Us -->
    <section class="contact-us py-5">
      <div class="container">
        <h2>Contact Us</h2>
        <p>If you have any questions about this Privacy Policy, please contact us:</p>
        <p>Email: <a href="mailto:privacy@miintafrica.com">privacy@miintafrica.com</a></p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
}
</script>

 <style>

/* General Styles */
body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  margin: 0;
  padding: 0;
  background-color: #f9f9f9;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

/* Hero Section */
.hero-section {
  background-color: #007BFF;
  color: white;
  padding: 40px 20px;
  text-align: center;
}

.hero-section h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
}

.hero-section p {
  font-size: 1.2rem;
}

/* Section Styles */
section {
  margin-bottom: 40px;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

h2 {
  font-size: 1.8rem;
  margin-bottom: 15px;
  color: #333;
}

p {
  margin-bottom: 15px;
  color: #555;
}

ul {
  list-style-type: disc;
  margin-left: 20px;
}

/* Alternate Background Colors */
.bg-light {
  background-color: #f8f8f8;
}

/* Contact Us Section */
.contact-us a {
  color: #007BFF;
  text-decoration: none;
}

.contact-us a:hover {
  text-decoration: underline;
}

/* Responsive Styles */
@media (max-width: 600px) {
  .hero-section h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.5rem;
  }
}

</style>